html {
    min-height: 100%;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.requerido {
    color: red;
}
.requerido::before {
    display: inline;
    content: '⚠ ';
}

.Toastify__toast {
    color: black;
}

.Toastify__toast--success {
    color: white;
}

.chakra-form__label {
    margin-bottom: 0 !important;
}

.chakra-modal__footer {
    padding-top: 0 !important;
}

.ag-theme-alpine-dark .ag-header {
    background-color: var(--chakra-colors-gray-900);
}

.ag-theme-alpine-dark .ag-root-wrapper {
    background-color: var(--chakra-colors-gray-800);
}

.ag-theme-alpine-dark .ag-row {
    background-color: var(--chakra-colors-gray-700);
}

.ag-theme-alpine-dark .ag-row-odd {
    background-color: var(--chakra-colors-gray-900);
}

.ag-theme-alpine-dark .ag-row-selected {
    background-color: var(
        --ag-selected-row-background-color,
        rgba(33, 150, 243, 0.3)
    );
}

.ag-theme-alpine-dark .ag-root-wrapper {
    border-radius: 4px;
}

.ag-theme-alpine .ag-root-wrapper {
    border-radius: 4px;
}

.vencidas-dark {
    color: fuchsia !important;
}

.vencidas-light {
    color: red !important;
}

.ag-pivot-off .ag-header-group-cell {
    font-size: 20px;
}
.ag-header-group-cell-label {
    justify-content: center;
}

